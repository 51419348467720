html, body {
  font-family: montserrat; }

@font-face {
  font-family: montserrat;
  src: url("../fonts/montserrat/Montserrat-Black.otf") format("woff");
  font-weight: 900; }

@font-face {
  font-family: montserrat;
  src: url("../fonts/montserrat/Montserrat-BlackItalic.otf") format("woff");
  font-weight: 900;
  font-style: italic; }

@font-face {
  font-family: montserrat;
  src: url("../fonts/montserrat/Montserrat-ExtraBold.otf") format("woff");
  font-weight: 800; }

@font-face {
  font-family: montserrat;
  src: url("../fonts/montserrat/Montserrat-ExtraBoldItalic.otf") format("woff");
  font-weight: 800;
  font-style: italic; }

@font-face {
  font-family: montserrat;
  src: url("../fonts/montserrat/Montserrat-Bold.otf") format("woff");
  font-weight: 700; }

@font-face {
  font-family: montserrat;
  src: url("../fonts/montserrat/Montserrat-BoldItalic.otf") format("woff");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: montserrat;
  src: url("../fonts/montserrat/Montserrat-SemiBold.otf") format("woff");
  font-weight: 600; }

@font-face {
  font-family: montserrat;
  src: url("../fonts/montserrat/Montserrat-SemiBoldItalic.otf") format("woff");
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: montserrat;
  src: url("../fonts/montserrat/Montserrat-Medium.otf") format("woff");
  font-weight: 500; }

@font-face {
  font-family: montserrat;
  src: url("../fonts/montserrat/Montserrat-MediumItalic.otf") format("woff");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: montserrat;
  src: url("../fonts/montserrat/Montserrat-Regular.otf") format("woff");
  font-weight: 400; }

@font-face {
  font-family: montserrat;
  src: url("../fonts/montserrat/Montserrat-Italic.otf") format("woff");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: montserrat;
  src: url("../fonts/montserrat/Montserrat-Light.otf") format("woff");
  font-weight: 300; }

@font-face {
  font-family: montserrat;
  src: url("../fonts/montserrat/Montserrat-LightItalic.otf") format("woff");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: montserrat;
  src: url("../fonts/montserrat/Montserrat-ExtraLight.otf") format("woff");
  font-weight: 200; }

@font-face {
  font-family: montserrat;
  src: url("../fonts/montserrat/Montserrat-ExtraLightItalic.otf") format("woff");
  font-weight: 200;
  font-style: italic; }

@font-face {
  font-family: montserrat;
  src: url("../fonts/montserrat/Montserrat-Thin.otf") format("woff");
  font-weight: 100; }

@font-face {
  font-family: montserrat;
  src: url("../fonts/montserrat/Montserrat-ThinItalic.otf") format("woff");
  font-weight: 100;
  font-style: italic; }

.newdoc__title {
  margin-bottom: 1rem;
  padding: .5rem;
  border-bottom: 1px solid #ccc; }
  .newdoc__title h4 {
    color: #000;
    font-weight: 700; }
    .newdoc__title h4 span {
      color: #e21d41; }

.newdoc__content-border {
  padding: 1rem 1rem 0rem 1rem;
  border: 1px solid #ccc;
  border-radius: .2rem;
  margin-bottom: 1rem; }
  .newdoc__content-border button, .newdoc__content-border .btn {
    margin-bottom: 1rem; }
  .newdoc__content-border a {
    color: #e21d41; }
  .newdoc__content-border a:hover, .newdoc__content-border a.active {
    background: #e21d41;
    color: #fff; }

.newdoc__actions {
  padding: 1rem 1rem 0rem 1rem;
  border: 1px solid #ccc;
  border-radius: .2rem; }
  .newdoc__actions nav {
    margin-bottom: 1rem; }
    .newdoc__actions nav .nav-link {
      color: #000 !important; }
  .newdoc__actions .nav-pills .nav-link.active, .newdoc__actions .nav-pills .nav-link:hover, .newdoc__actions .nav-pills .show > .nav-link {
    color: #fff !important;
    background-color: #e21d41 !important; }

.btn-congress {
  color: #fff !important;
  background-color: #e21d41;
  border-color: #e21d41; }
  .btn-congress:hover {
    color: #fff;
    background-color: #d00c30;
    border-color: #d00c30; }

.btn-back {
  color: #000 !important; }
  .btn-back:hover {
    color: #e21d41 !important;
    text-decoration: underline;
    background-color: transparent !important; }

.upload-actions {
  display: block;
  color: #000 !important; }
  .upload-actions:hover {
    color: #e21d41 !important;
    background: transparent !important; }

.main-login {
  padding: 10px; }
  .main-login__actions {
    color: #000;
    display: block;
    margin-bottom: .1rem;
    font-weight: bold; }
    .main-login__actions:hover {
      color: #e21d41; }

/*
   * Navbar
   */
.navbar-brand {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: 1rem;
  font-weight: bold;
  white-space: normal; }

.bg-main {
  background: #e21d41;
  position: sticky;
  top: 0;
  z-index: 9999; }

.feather {
  vertical-align: center;
  padding-right: .3rem; }

/*
   * Sidebar
   */
.main-container {
  min-height: calc(100vh - 48px); }

.sidebar {
  z-index: 100;
  /* Behind the navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  padding: 0 !important; }

.sidebar-sticky {
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto;
  /* Scrollable contents if viewport is shorter than content. */ }

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
  margin-bottom: .1rem; }

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999; }

.sidebar .nav-link.active, .sidebar .nav-link:hover {
  background: #e21d41;
  color: #fff; }

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit; }

.sidebar-heading {
  text-transform: uppercase;
  color: #000000 !important;
  border-bottom: 1px solid #000;
  padding-bottom: .5rem; }

/*
   * Content
   */
main {
  padding: .5rem;
  padding-bottom: 5rem; }

.page-content {
  margin-top: 2rem; }

.page-title {
  font-size: 1.45rem;
  border-bottom: 1px solid #000;
  padding-bottom: .5rem; }

.page-actions {
  margin-top: 2rem; }
  .page-actions__item a {
    text-align: center;
    color: #e21d41;
    font-size: 1.25rem; }
  .page-actions__item a:hover {
    text-decoration: none;
    color: #000; }
  .page-actions__item i {
    display: block;
    font-size: 4rem;
    margin-bottom: .5rem; }
  .page-actions__item span {
    display: block;
    font-weight: 600; }

.lang {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 2rem;
  flex-direction: column; }
  .lang-item {
    margin: .1rem 1rem;
    color: #000;
    font-weight: 600;
    display: block; }
    .lang-item:hover, .lang-item.active {
      text-decoration: none;
      color: #e21d41; }
    .lang-item img {
      width: 24px;
      vertical-align: middle; }
